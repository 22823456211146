import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { IconButton, useTheme, Divider, useMediaQuery, Button } from "@material-ui/core";
import { Typography, Box, Grid } from "@material-ui/core";
import { Link, useHistory } from 'react-router-dom';
import { useState } from "react";
import { useScrollTrigger } from "@material-ui/core";
import { textTransform } from "../../aux/aux";
import { MenuItem } from '@mui/material';

import PopUpLogInMenu from '../PopUpLogInMenu/PopUpLogInMenu';
import PopUpUserMenu from "../PopUpUserMenu/PopUpUserMenu";

import Logo from "../../img/logo-alpha.svg";
import LogoNeg from "../../img/logo-alpha-neg.svg";
import MenuIcon from "@material-ui/icons/Menu";


import PopUpCreateAccount from "../PopUpCreateAccount/PopUpCreateAccount";
import { isUserLogged } from "../../aux/sessionHelpers";
import HamburguerMenu from "../HamburguerMenu/HamburguerMenu";
import PopUpFindSpecialist from "../PopUpFindSpecialist/PopUpFindSpecialist";
// Style
import "./headerLaunch.css";

const HeaderLaunch = ({ user, setUser, addMessages, elevation=4 }) => {
    const POP_UP_WIDTH = 380;
    const HEADER_HEIGHT = 60;
    const theme =  useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'headerLaunch' });
    const isScrollTriggered = useScrollTrigger({ disableHysteresis:true, threshold: 100 });
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [hover, setHover] = useState(false);
    const [hamburguerMenuAnchorEl, setHamburguerMenuAnchorEl] = useState(null);
    const [isLogoAnimationEnded, setIsLogoAnimationEnded] = useState(false);
    const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
    const [showFindSpecialistModal, setShowFindSpecialistModal] = useState(false);
    const styles = {
        mainContainer:{
            height: `${HEADER_HEIGHT}px`,
            backgroundColor: isScrollTriggered ? 'white' : 'transparent',
            boxShadow: isScrollTriggered ? theme.shadows[elevation] : theme.shadows[0],
        },
        icon:{
            display:'block',
            textDecoration: 'none !important'
        }
    }

    const onMouseEnter = (e) => {
        e.target.style.textDecoration= `underline ${theme.palette.primary.main}`;
    }
    const onMouseLeave = (e) => {
        e.target.style.textDecoration= "none";
    }

    const onMouseL = () => {
        setHover(false);
    }
    const onMouseE = () => {
        setHover(true);
    }
    const onOpenHambugerMenu = (e) => {
        e.preventDefault();
        setHamburguerMenuAnchorEl(e.currentTarget);
    }
    const onCloseHamburguerMenu = (e) => {
        e.preventDefault();
        setHamburguerMenuAnchorEl(null)
    }
    const onShowCreateAccountModal = (e) => {
        e.preventDefault();
        setShowCreateAccountModal(true);
    }

    const onShowFindSpecialistModal = (e) => {
        e.preventDefault();
        setShowFindSpecialistModal(true);
    }

    const onLogoAnimationEnd = (e) => {
        if(e.animationName === 'translation-center'){
            setTimeout(() => setIsLogoAnimationEnded(true), 300);
        }
    }

    useEffect(() => {
        if(!matchMobile){
            setIsLogoAnimationEnded(false);
        }
    },[matchMobile])

    // useEffect(() => {
    //     const observer = new MutationObserver((elements) => {
    //         const bodyContainer = elements[0];
    //         const overflow = bodyContainer.target.style.overflow;
    //         const position = bodyContainer.target.style.position;
    //         setIsDocScrollable(position !== 'fixed' && overflow !== 'hidden');
    //     });
    //     observer.observe(document.getElementsByTagName('body')[0], {
    //         attributes: true,
    //         attributeFilter: ['style']
    //     })
    //     return(() => {
    //         observer.disconnect();
    //     })
    // },[])

    return (
            <div className='header-landing-wrapper'>
                <header className='header-landing-main-container' style={styles.mainContainer}>
                    {showCreateAccountModal &&
                        <PopUpCreateAccount showModal={showCreateAccountModal} setShowModal={setShowCreateAccountModal} setUser={setUser} user={user} passiveVerification={true} />
                    }
                    {showFindSpecialistModal &&
                        <PopUpFindSpecialist showModal={showFindSpecialistModal} onClickClose={() => setShowFindSpecialistModal(false)} width={POP_UP_WIDTH} />
                    }
                    {matchMobile ?
                        <div className='header-landing-toolbar'>
                            {isLogoAnimationEnded &&
                                <div 
                                className="header-landing-user-menu-container"
                                >
                                    <IconButton onClick={onOpenHambugerMenu} size={'small'}>
                                        <MenuIcon/>
                                    </IconButton>
                                    <HamburguerMenu 
                                        anchorEl={hamburguerMenuAnchorEl}
                                        onClose={onCloseHamburguerMenu}
                                        closeOnClick={true}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        transformOrigin={{ vertical:'top', horizontal: 'center'}}
                                        paperProps={{ elevation:0, className:'header-landing-hamburguer-paper'}}
                                    >
                                        <MenuItem onClick={onShowFindSpecialistModal}>
                                            <Typography variant="body1">
                                                {`${textTransform('title', t('findAnSpecialist'))}`}
                                            </Typography>
                                        </MenuItem>
                                        <Divider/>
                                        <MenuItem onClick={onShowCreateAccountModal}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                name="createUser"
                                            >
                                                {t('preJoinNow')}
                                            </Button>
                                        </MenuItem>
                                    </HamburguerMenu>
                                </div>
                            }
                            <div className={`${isLogoAnimationEnded ? 'header-landing-logo-container': 'header-landing-logo-container-animated'}`} onAnimationEnd={onLogoAnimationEnd} >
                                <Link to="/" {... matchMobile ? {} : {onMouseEnter: onMouseE, onMouseLeave: onMouseL}}>
                                    {hover ? <LogoNeg alt="logo" height={30} style={styles.icon} /> : <Logo alt="logo" height={30} style={styles.icon} />} 
                                </Link>
                            </div>
                        </div>
                    :
                        <div className='header-landing-toolbar'>
                            <div className='header-landing-logo-container' >
                                <Link to="/" {... matchMobile ? {} : {onMouseEnter: onMouseE, onMouseLeave: onMouseL}}>
                                    {hover ? <LogoNeg alt="logo" height={30} style={styles.icon} /> : <Logo alt="logo" height={30} style={styles.icon} />} 
                                </Link>
                            </div>
                            <div
                                className="header-landing-links-container" 
                            >
                                <div className='header-landing-bar-nav-elem'>
                                    <Typography 
                                        //color="grey"
                                        variant= "body2"
                                        className="header-landing-text-link header-landing-text-cickable"
                                        onMouseEnter={onMouseEnter}
                                        onMouseLeave={onMouseLeave}
                                        onClick={onShowFindSpecialistModal}
                                        >
                                            {`${textTransform('title', t('findAnSpecialist'))}`}
                                    </Typography>
                                </div>
                                <Divider orientation="vertical" flexItem variant="middle"/>
                            </div>              
                            <div className="header-landing-user-menu-container">
                                <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={onShowCreateAccountModal}
                                        className="header-landing-links-button"
                                    >
                                        {t('preJoinNow')}
                                </Button>
                            </div>
                        </div>
                    }
                </header>
            </div>
    )
}

export default HeaderLaunch;
