import { useLocation } from 'react-router-dom';
import { Divider, Paper } from '@material-ui/core'
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks'
import { useTranslation } from 'react-i18next'
import MailIcon from '@mui/icons-material/Mail';
import PopUpModalHeader from '../../components/PopUpModalHeader/PopUpModalHeader'
import { useEffect, useState } from 'react';
import ButtonLoading from '../../components/ButtonLoading/ButtonLoading';
import { TextField } from '@mui/material'
import { createUpdateMktProfile, subscriptionListSubscription } from '../../services/visitorServices';
import { getFromSafeObject, textTransform } from '../../aux/aux';
import {Alert} from '@mui/material';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { useRedirect } from '../../customHooks/useRedirect';
import './emailSubscribeSubscriptionList.css'
import klaviyoWrapper from '../../aux/klaviyoHelpers';
import { isUserLogged } from '../../aux/sessionHelpers';

const EmailSubscribeSubscriptionList = ({ user }) => {
	const REDIRECT_TO_EMAIL_PREFERENCES = '/subscription-list';
	const SUBSCRIPTION_LIST_NAME_ARRAY=['Newsletter', 'Marketing'];
	const { t } = useTranslation('emailSubscribeSubscriptionList');
	const location = useLocation()
	const query = new URLSearchParams(location.search);
	const redirectApi = useRedirect();
	const localeCtx = useLocaleCtx();
	const [form, setForm] = useState({email: query.get('email') || ''});
	const [emailToSubscribe, setEmailToSubscribe] = useState(null);
	const [isRequesting, setIsRequesting] = useState(false);
	const [isCreatingProfileError, setIsCreatingProfileError] = useState(false);

	const createMktProfileAndSubscribeAllList = async(email, signal) => {
		try{
			setIsRequesting(true);
			const profile = await createMktProfile(email,signal);
			if(profile.email){
				await subscribeAllSubscriptionList(profile.email, SUBSCRIPTION_LIST_NAME_ARRAY, signal);
				if(!signal.aborted){
					setIsRequesting(false);
					redirectApi.redirectToRoute(REDIRECT_TO_EMAIL_PREFERENCES, { email });
				}
			}else{
				throw new Error('mkt profile could not be created');
			}
		}catch(error){
			if(!signal.aborted){
				setIsRequesting(false);
				setIsCreatingProfileError(true);
			}
		}
	}

	const createMktProfile = async(email, signal) => {
		// Profile is created/updated when the user account is created
		// This ensures to append to it info related to location
		let profile;
		if(!isUserLogged(user)){
			profile = await klaviyoWrapper.idenfifyProfile(email, { locale_code:localeCtx.localeCode })
				.then(res => {
					return new Promise(resolve => resolve({ email }));
				})
				.catch(async error => {
					try{
						// Cannot identify profile from client-side
						const res = await createUpdateMktProfile('klaviyo', { email }, { locale_code: localeCtx.localeCode }, 0, signal);
						return new Promise(resolve => resolve({ email }));
					}catch(error){
						return new Promise((resolve, reject) => reject(error));
					}
				})
		}else{
			// User is logged and we do not identify cookie with this new profile
			await createUpdateMktProfile('klaviyo', { email }, { locale_code: localeCtx.localeCode }, 0, signal);
			profile = { email };
		}
		return profile;
	}

	const subscribeAllSubscriptionList = async(email, subscriptionListNameArray, signal) => {
		try{
			const listArray = subscriptionListNameArray.map(listName => ({name:listName, custom_source: 'mail-subscription'}))
            await subscriptionListSubscription('subscribe', listArray, [ { email } ], 0, signal)
		}catch(error){
			// Do not show anything. It is shown at emailSubscriptionListPreferences
		}
	}

	useEffect(() => {
		if(emailToSubscribe){
			const abortController = new AbortController();
			createMktProfileAndSubscribeAllList(emailToSubscribe, abortController.signal);
			return(() => abortController.abort());
		}
	},[emailToSubscribe])

    return (
		<div className='email-subscription-list-wrapper'>
			<div className='email-subscription-list-main-container'>
				<Paper className='email-subscription-list-modal-container'>
					<PopUpModalHeader title={t('subscribeToEmailList')} Icon={MailIcon}/>
					<Divider />
					<form onSubmit={(e) => {e.preventDefault(); setEmailToSubscribe(prev => (form.email))}}>
						<div className='email-subscription-list-email-identifying-container'>
							{isCreatingProfileError ?
								<Alert severity='error'>
									{`${textTransform('title',t('cannotSubscribeYouNow'))}. ${textTransform('title',t('tryItLater'))}`}
								</Alert>
								:
								null
							}	
							<TextField  
								label={textTransform('title',t('insertYourEmail'))} 
								variant='standard'
								name="email"
								type="email" 
								value={form.email}
								onChange={(e) => {e.preventDefault(); setEmailToSubscribe(null); setIsCreatingProfileError(false); setForm(prev => ({...prev, email:e.target.value}))}} 
								disabled={isRequesting} 
								required 
								fullWidth 
								inputProps={{style:
									{
										textOverflow:'ellipsis'
									}
								}}
							/>
							<ButtonLoading 
								isLoading={isRequesting} 
								disabled={isCreatingProfileError}
								color="primary" 
								variant="outlined" 
								type={'submit'} 
								label={t('next')}
								fullWidth 
							/>
						</div>
					</form>
				</Paper>
			</div>
			<BigFooterNoLinks user={user} />
		</div>
    )
}

export default EmailSubscribeSubscriptionList