import { useLocation } from 'react-router-dom';
import { Divider, Paper, Switch, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks'
import { useTranslation } from 'react-i18next'
import MailIcon from '@mui/icons-material/Mail';
import PopUpModalHeader from '../../components/PopUpModalHeader/PopUpModalHeader'
import { useEffect, useRef, useState } from 'react';
import { isUserLogged } from '../../aux/sessionHelpers';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import ButtonLoading from '../../components/ButtonLoading/ButtonLoading';
import { TextField } from '@mui/material'
import { createUpdateMktProfile, getSubscriptionList, subscriptionListSubscription } from '../../services/visitorServices';
import { fromArrayOfObjectsToMap, getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import {Alert} from '@mui/material';
import './emailSubscriptionListPreferences.css'
import { getUserProfile } from '../../services/userServices';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { useRedirect } from '../../customHooks/useRedirect';

const EmailSubscriptionListPreferences = ({ user }) => {
	const REDIRECT_TO_SUBSCRIBE_TO_LIST = '/subscription-list/subscribe'
	const { t } = useTranslation('emailSubscriptionListPreferencias');
	const theme = useTheme();
	const location = useLocation()
	const redirectApi = useRedirect();
	const query = new URLSearchParams(location.search);
	const localeCtx = useLocaleCtx();
	const componentStateArray = ['loading', 'identifyingUser', 'preferences'];
	const [form, setForm] = useState({email: query.get('email') || ''});
	const [userEmail, setUserEmail] = useState(null);
	const [subscribedListMap, setSubscribedListMap] = useState(null);
	const [isRequesting, setIsRequesting] = useState(false);
	const [isRequestingError, setIsRequestingError] = useState(false);
	const [isUpdateError, setIsUpdateError] = useState(false);
	const [updateSubscriptionListMap, setUpdateSubscriptionListMap] = useState({Marketing:false, Newsletter:false});
	const [componentState, setComponentState] = useState(componentStateArray[0]);
	const abortControllerRef = useRef(null);

	const requestUserEmail = async(userId, signal) => {
		try{
			setComponentState(componentStateArray[0]);
			const res = await getUserProfile(userId, null, 0, signal);
			const {
				userProfile
			} = res.data;
			const email = getFromSafeObject(userProfile, 'email');
			if(email){
				setUserEmail(userProfile.email)
			}else{
				throw new Error('cannot access to the user profile')
			}
		}catch(err){
			setComponentState(componentStateArray[1])
		}
	}

    const requestEmailSubscriptionList = async(email, signal) => {
		try{
			setIsRequesting(true);
			const res = await getSubscriptionList('klaviyo', [ { email } ], 0, signal);
			const {
				profileSubscriptionListMap
			} = res.data
			const profileSubscriptionList = profileSubscriptionListMap[email];
			if(profileSubscriptionList){
				const subscribedListArray = profileSubscriptionList.subscribedListArray;
				const subscribedListMap = fromArrayOfObjectsToMap(subscribedListArray, 'name');
				if(!signal.aborted){
					setSubscribedListMap(subscribedListMap);
				}
			}else{
				redirectApi.redirectToRoute(REDIRECT_TO_SUBSCRIBE_TO_LIST, query, true);
			}
		}catch(error){
			if(!signal.aborted){
				setIsRequestingError(true);
			}
		}finally{
			if(!signal.aborted){
				setIsRequesting(false);
			}
		}
    }

    const onUpdateEmailSubscriptionList = async(listName, subscribe, signal=abortControllerRef.current.signal) => {
        try{
            setUpdateSubscriptionListMap(prev => ({...prev, [listName]: true}))
            const op = subscribe ? 'subscribe' : 'unsubscribe';
			let listArray = [{
				name:listName,
				...subscribe ? { custom_source: 'mail-preferences' } : {}
			}] 
            const res = await subscriptionListSubscription(op, listArray, [ { email: userEmail } ], 0, signal)
            const {
				successListArray,
				errorListArray 
			}= res.data;
			const list = successListArray[0];
            if(list.name === listName && list.emailArray.includes(userEmail)){
				setSubscribedListMap(prev => ({ ...prev, [list.name]: op === 'subscribe' ? {name:list.name, id:list.id} : null}))
            }else{
                throw new Error('invalid updated list');
            }
        }catch(err){
            setIsUpdateError(true);
        }finally{
            setUpdateSubscriptionListMap(prev => ({...prev, [listName]: false}))
        }
    }


	useEffect(() => {
		abortControllerRef.current = new AbortController();
		return(() => abortControllerRef.current.abort())
	},[]);

	useEffect(() => {
		if(user){
			const abortController = new AbortController();
			requestUserEmail(user.basicData.id, abortController.signal)
			return(() => abortController.abort())
		}else{
			setUserEmail(query.get('email') || null);
		}
	},[user])

	useEffect(() => {
		if(userEmail){
			const abortController = new AbortController();
			requestEmailSubscriptionList(userEmail, abortController.signal);
			return(() => abortController.abort());
		}else{
			setSubscribedListMap(null);
		}
	},[userEmail])

	useEffect(() => {
		if(!user && !userEmail){
			setComponentState(componentStateArray[1]);
		}else if(subscribedListMap){
			setComponentState(componentStateArray[2]);
		}
	},[user, userEmail, subscribedListMap])

	const getComponentToRender = () => {
		let Component;
		switch(componentState){
			case componentStateArray[0]:
				Component = 
					<div className='email-subscription-list-email-loading-container'>
						<LoadingComponent visibleElements='circle' />
					</div>
				break;
			case componentStateArray[1]:
				Component =
					<form onSubmit={(e) => {e.preventDefault(); setUserEmail(prev => (form.email))}}>
						<div className='email-subscription-list-email-identifying-container'>
							{isRequestingError ?
								<Alert severity='error'>
									{`${textTransform('title',t('cannotAccessToYourEmailPreferencesNow'))}. ${textTransform('title',t('tryItLater'))}`}
								</Alert>
								:
								null
							}	
							<TextField  
								label={t('email')} 
								variant='standard'
								name="email"
								type="email" 
								value={form.email}
								onChange={(e) => {e.preventDefault(); setUserEmail(null); setIsRequestingError(false); setForm(prev => ({...prev, email:e.target.value}))}} 
								disabled={isRequesting || query.has('email')} 
								required 
								fullWidth 
								inputProps={{style:
									{
										textOverflow:'ellipsis'
									}
								}}
							/>
							<ButtonLoading 
								isLoading={isRequesting} 
								disabled={isRequestingError}
								color="primary" 
								variant="outlined" 
								type={'submit'} 
								label={t('next')}
								fullWidth 
							/>
						</div>
					</form>
				break;
			case componentStateArray[2]:
				Component = 
					<div className='email-subscription-list-preferences-container'>
						{isUpdateError ?
							<Alert severity='error'>
								{`${textTransform('title',t('cannotUpdateYourEmailPreferencesNow'))}. ${textTransform('title',t('tryItLater'))}`}
							</Alert>
							:
							null
						}
						<div className='email-subscription-list-preferences-menu-inline-field-group-container'>
							<Typography variant={'body2'}>
								{textTransform('title', t("promotions"))}
							</Typography>
							<Typography variant={'caption'}>
								{textTransform('title', t("receiveOffersDiscountsAndExclusiveContents"))}
							</Typography>
							<div className='email-subscription-list-preferences-menu-field-value-button-container'>
								<div className='email-subscription-list-preferences-menu-field-load-button-container'>
									{updateSubscriptionListMap.Marketing ?
										<LoadingComponent visibleElements="circle" circleSize={theme.typography.body1.fontSize}/>
										:
										null
									}
									<Switch color='primary' disabled={isUpdateError || isRequestingError || updateSubscriptionListMap.Marketing} checked={isNotEmptyObject(getFromSafeObject(subscribedListMap,'Marketing'))} onChange={(e, checked) => onUpdateEmailSubscriptionList('Marketing', checked)} size={"small"} />
								</div>
							</div>
						</div>
						<div className='email-subscription-list-preferences-menu-inline-field-group-container'>
							<Typography variant={'body2'}>
								{textTransform('title', t("newsletter"))}
							</Typography>
							<Typography variant={'caption'}>
								{textTransform('title', t("receiveOurNewsLetter"))}
							</Typography>
							<div className='email-subscription-list-preferences-menu-field-value-button-container'>
								<div className='email-subscription-list-preferences-menu-field-load-button-container'>
									{updateSubscriptionListMap.Newsletter ?
										<LoadingComponent visibleElements="circle" circleSize={theme.typography.body1.fontSize}/>
										:
										null
									}
									<Switch color='primary' disabled={isUpdateError || isRequestingError || updateSubscriptionListMap.Newsletter} checked={isNotEmptyObject(getFromSafeObject(subscribedListMap,'Newsletter'))} onChange={(e, checked) => onUpdateEmailSubscriptionList('Newsletter', checked)} size={"small"}/>
								</div>
							</div>
						</div>
					</div>
				break;
		}
		return Component
	}

    return (
		<div className='email-subscription-list-wrapper'>
			<div className='email-subscription-list-main-container'>
				<Paper className='email-subscription-list-modal-container'>
					<PopUpModalHeader title={t('updateEmailPreferences')} Icon={MailIcon}/>
					<Divider />
					{getComponentToRender()}
				</Paper>
			</div>
			<BigFooterNoLinks user={user} />
		</div>
    )
}

export default EmailSubscriptionListPreferences