import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDisplayablePromoCodeCtx } from '../../customHooks/useDisplayablePromoCodeCtx'
import { isNotEmptyObject } from '../../aux/aux'
import { isPromoCodeDisplayableEveryWhere } from '../../aux/displayablePromoCodeHelpers'
import './landing.css'
import BannerPromo from '../../components/BannerPromo/BannerPromo';
import LandingHeroLaunch from '../../components/LandingHeroLaunch/LandingHeroLaunch';
import SmallFooter from '../../components/SmallFooter/SmallFooter';
import { useTheme } from '@material-ui/styles';

const Landing = ({ user, setUser, promoContainerRef=null }) => {
    const launchingDate = new Date('2025-03-12 00:00:00+01:00');
    const theme = useTheme()
    const { t } = useTranslation('landing');
    const displayablePromoCodeCtx = useDisplayablePromoCodeCtx();
    const [activeOpenPromo, setActiveOpenPromo] = useState(null)
    const [showPromo, setShowPromo] = useState(false);

    const handleClosePromo = (e) => {
        e.preventDefault();
        setShowPromo(false);
    }
    
    useEffect(() => {
        let activePromoCode = null
        if(isNotEmptyObject(displayablePromoCodeCtx)){
            const isDisplayable = isPromoCodeDisplayableEveryWhere(displayablePromoCodeCtx);
            if(isDisplayable){
                activePromoCode = displayablePromoCodeCtx;
            }
        }
        setActiveOpenPromo(activePromoCode);
    },[displayablePromoCodeCtx])

    useEffect(() => {
        if(activeOpenPromo && promoContainerRef && promoContainerRef.current != null){
            setShowPromo(true);
        }else{
            setShowPromo(false);
        }
    },[activeOpenPromo])

    return (
        <div className='landing-main-container'>
            <LandingHeroLaunch setUser={setUser} user={user} launchingDate={launchingDate} />
            <SmallFooter darkMode={true} style={{backgroundColor:theme.palette.background.dark}}/>
            {/* <BigFooter /> */}
        </div>
    )
}

export default Landing