import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Button, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles'
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import decorationHeroAnimation from '../../lotties/decoration-floating-hero_1920x1698.json';
import PopUpCreateAccount from '../PopUpCreateAccount/PopUpCreateAccount';
import { useEffect } from 'react';
import { onOnceEventListener } from '../../aux/internalCommunicationHelpers';
import './landingHeroLaunch.css'
import StyledHTMLString from '../StyledHTMLString/StyledHTMLString';
import CountDown from '../CountDown/CountDown';

const LandingHeroLaunch = ({ user, setUser, launchingDate=new Date()}) => {
    const imgURL = '/img/hero-img-window.svg';
    const { t, i18n } = useTranslation('landing', { keyPrefix: 'landingHeroLaunch' });
    const theme = useTheme();
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
    const [animationContainer, setAnimationContainerRef] = useState(null);
    const [isAnimationReady, setIsAnimationReady] = useState(false);
    const [showAnimationContainer, setShowAnimationContainer] = useState(false);
    const [isImgReady, setIsImgReady] = useState(false);
    const imgWasShownAtRef = useRef(null);
    const animationRef = useRef(null); 
    const styles = {
        wrapper:{
            backgroundColor: theme.palette.primary.lighter
        },
        backgroundImg:{
            fill: theme.palette.primary.light
        },
        title:{
            color: theme.palette.primary.darker,
            fontWeight: 'bold'
        },
        comingSoon:{
            color: theme.palette.text.contrast,
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
            padding:'0px 8px', 
            display: 'inline-block'
        },
        subTitle:{
            color: theme.palette.primary.darker,
        }
    }

    const handleOnClick = (e) => {
        e.preventDefault();
        setShowCreateAccountModal(true);
    }

    const showing = () => {
        setIsImgReady(true);
    }

    useEffect(() => {
        if(animationContainer != null){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                animationData:decorationHeroAnimation,
                renderer: 'svg',
                autoplay: true,
                loop:10,
                rendererSettings:{
                    progressiveLoad:true // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
                }
            })
            onOnceEventListener('enterFrame', () => { setIsAnimationReady(true) }, animationRef.current);
        }
        return(() => {
            if(animationRef.current != null){
                animationRef.current.stop();
                animationRef.current?.destroy();
            }
        })
    },[animationContainer])

    useEffect(() => {
        if(isAnimationReady){
            const timeoutId = setTimeout(() => setShowAnimationContainer(true), 500);
            return(() => clearTimeout(timeoutId))
        }else{
            const date = new Date();
            imgWasShownAtRef.current = date.getUTCMilliseconds();
        }
    },[isImgReady])

    useEffect(() => {
        if(isImgReady){
            const date = new Date();
            const timeFormImgWasShown = date.getUTCMilliseconds() - imgWasShownAtRef.current;
            const timeToShowAnimationContainer = Math.max(parseInt(300 - timeFormImgWasShown), 0);
            const timeoutId = setTimeout(() => setShowAnimationContainer(true), timeToShowAnimationContainer);
            return(() => clearTimeout(timeoutId))
        }
    },[isAnimationReady])

    useEffect(() => {
        if(isAnimationReady){
            const timeoutId = setTimeout(() => setShowAnimationContainer(true), 500);
            return(() => clearTimeout(timeoutId))
        }else{
            const date = new Date();
            imgWasShownAtRef.current = date.getUTCMilliseconds();
        }
    },[isImgReady])

    useEffect(() => {
        if(isImgReady){
            const date = new Date();
            const timeFormImgWasShown = date.getUTCMilliseconds() - imgWasShownAtRef.current;
            const timeToShowAnimationContainer = Math.max(parseInt(300 - timeFormImgWasShown), 0);
            const timeoutId = setTimeout(() => setShowAnimationContainer(true), timeToShowAnimationContainer);
            return(() => clearTimeout(timeoutId))
        }
    },[isAnimationReady])

    return (
        <div className='landing-hero-launch-wrapper' style={styles.wrapper}>
            {/* <img src='/img/background-corner.svg' className='landing-hero-launch-background-img' /> */}
            {showCreateAccountModal &&
                <PopUpCreateAccount showModal={showCreateAccountModal} setShowModal={setShowCreateAccountModal} setUser={setUser} user={user} passiveVerification={true}/>
            }
            { matchDesktop ? 
                <Grid container className='landing-hero-launch-main-container' alignItems='center' justifyContent='center'>
                    <Grid item xs className='landing-hero-launch-text-container'>
                        <Grid container direction='column'>
                            <Grid item container className='landing-hero-launch-countdown-container'>
                                <Grid item>
                                    <Typography variant='h4'>
                                        {t('common:symbolParty')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <CountDown size='medium' endsDate={launchingDate} stylesMap={{label:{color:theme.palette.primary.dark}}}/>
                                </Grid>
                                <Grid item>
                                    <Typography variant='h4'>
                                        {t('common:symbolRocket')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item className='landing-hero-launch-title'>
                                <StyledHTMLString typographyProps={{variant:'h4', style:styles.comingSoon}}>
                                    {t('comingSoon')}
                                </StyledHTMLString>
                                <StyledHTMLString typographyProps={{variant:'h3', style:styles.title}}>
                                    {t('theBestTrainingForYourDevice')}
                                </StyledHTMLString>
                            </Grid>
                            <Grid item className='landing-hero-launch-subtitle'>
                                <StyledHTMLString typographyProps={{variant:'h6', style:styles.subTitle}}>
                                    {t('preRegisterAndHaveBenefits')}
                                </StyledHTMLString>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color='primary' onClick={handleOnClick}>
                                    {t('preJoinNow')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={'landing-hero-launch-img-container'}>
                        <div ref={setAnimationContainerRef} className={`landing-hero-launch-img-animation ${showAnimationContainer ? ' show' : ''}`}/>
                        <img src={imgURL} className={`landing-hero-launch-img${isImgReady ? ' show' : ''}`} onLoad={() => {setIsImgReady(true)}}/>
                    </Grid>
                </Grid>
                :
                <Grid container direction='column' className='landing-hero-launch-main-container' alignItems='center' justifyContent='center'>
                    <Grid item className={`landing-hero-launch-img-container${isImgReady ? ' show' : ''}`}>
                        <div ref={setAnimationContainerRef} className={`landing-hero-launch-img-animation ${showAnimationContainer ? ' show' : ''}`}/>
                        <img src={imgURL} className={`landing-hero-launch-img${isImgReady ? ' show' : ''}`} onLoad={() => {setIsImgReady(true)}}/>
                    </Grid>
                    <div className='landing-hero-launch-text-container'>
                        <Grid item container className='landing-hero-launch-countdown-container'>
                            <Grid item>
                                <Typography variant={matchMobile ? 'h4':'h3'}>
                                    {t('common:symbolParty')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <CountDown size={matchMobile ? 'medium':'large'} endsDate={launchingDate} stylesMap={{label:{color:theme.palette.primary.dark}}}/>
                            </Grid>
                            <Grid item>
                                <Typography variant={matchMobile ? 'h4':'h3'}>
                                    {t('common:symbolRocket')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item className='landing-hero-launch-title' align='center'>
                            <StyledHTMLString typographyProps={{variant:matchMobile ? 'h4':'h3', style:styles.comingSoon}}>
                                {t('comingSoon')}
                            </StyledHTMLString>
                            <StyledHTMLString typographyProps={{variant:matchMobile ? 'h4':'h3', style:styles.title}}>
                                {t('theBestTrainingForYourDevice')}
                            </StyledHTMLString>
                        </Grid>
                        <Grid item className='landing-hero-launch-subtitle' align='center'>
                            <StyledHTMLString typographyProps={{variant:'body1', style:styles.subTitle}}>
                                {t('preRegisterAndHaveBenefits')}
                            </StyledHTMLString>
                        </Grid>
                        <Grid item className='landing-hero-launch-button' align='center'>
                            <Button variant='contained' color='primary' size={'large'} onClick={handleOnClick}>
                                {t('preJoinNow')}
                            </Button>
                        </Grid>
                    </div>
                </Grid>
            }
        </div>
    )
}

export default LandingHeroLaunch